import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '245, 247, 248',
		'primary-dark': '0, 0, 0',
		'accent': '221, 51, 38',
		'accent-plus': '255, 255, 255',
	},
});
